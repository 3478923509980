/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  logo: 'pythagoras.svg',
  logoalt: 'pythagoras-alt.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_pythagoras.scss',
  name: 'Pythagoras music fund',
  phone: '',
  email: 'michielboere@pythagorasmusicfund.com',
  website: 'https://pythagorasmusicfund.com/',
  glossary: true,
  langFileName: 'pythagoras.ts',
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  requireLegalBanner: true,
  chartColors: {
    greys: {
      color1: '#b1b3bd',
      color2: '#f7f7f7',
    },
    projectColors: {
      color1: '#045193',
      color2: '#4aa6f5',
      color3: '#083f6f',
      color4: '#307ec1',
    },
  },
};
