import VModal from 'vue-js-modal';
import VueGtm from '@gtm-support/vue2-gtm';
import Vue, { VNode } from 'vue';
import VueMoment from 'vue-moment';
import VueMeta from 'vue-meta';
import { registerLocale } from 'i18n-iso-countries';
import BootstrapVue from 'bootstrap-vue';
import GmapVue from 'gmap-vue';
import VueLazyload from 'vue-lazyload';
import { VLazyImagePlugin } from 'v-lazy-image';
import VTooltip from 'v-tooltip';
import VueYoutube from 'vue-youtube';
import IdleVue from 'idle-vue';
import firebase from 'firebase/app';
import { Promised } from 'vue-promised';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { roles } from '@/store/models/user';
import { i18n } from './i18n';
// Custom hooks must to be registered before any other components are imported
// @see https://class-component.vuejs.org/guide/additional-hooks.html
import './class-component-hooks';
import App from './App.vue';
import './scss/global/global.scss';
import router from './router';
import store from './store';
import './filters';
import 'hooper/dist/hooper.css';
import { intercom } from '../whitelabel.config';

const { intercomConfig } = require('../whitelabel.config');

const env = process.env;
const NODE_ENV = env.NODE_ENV;
const isDevelopmentEnvironment = NODE_ENV === 'development';
const isProductionEnvironment = NODE_ENV === 'production';

/**
 * Register countries list in English locale
 *
 * @see https://github.com/michaelwittig/node-i18n-iso-countries
 */
registerLocale(require('i18n-iso-countries/langs/en.json'));
registerLocale(require('i18n-iso-countries/langs/nl.json'));

Vue.config.devtools = !isProductionEnvironment;
Vue.config.performance = !isProductionEnvironment;
Vue.config.productionTip = false;

Vue.use(VueYoutube);
Vue.use(VModal, { dialog: true });
Vue.use(VueMoment);
Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(VueLazyload);
Vue.use(VLazyImagePlugin);
Vue.use(GmapVue, {
  load: {
    key: env.VUE_APP_BLOQIFY_FIREBASE_API_KEY,
    libraries: 'places',
  },
});
Vue.use(IdleVue, {
  store,
  // 3 hours at dev, 30 mins at prod
  idleTime: isDevelopmentEnvironment ? 10_800_000 : 1_800_000,
});
// @ts-ignore
Vue.component('Promised', Promised);
// Tooltip plugin
// @see https://github.com/Akryum/v-tooltip
Vue.use(VTooltip);

if (env.VUE_APP_GOOGLE_GTM) {
  const analyticsConfig = {
    id: env.VUE_APP_GOOGLE_GTM,
    enabled: isProductionEnvironment, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: !isProductionEnvironment, // Whether or not display console logs debugs (optional)
    loadScript: true,
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
  };

  Vue.use(VueGtm, analyticsConfig);
}

if (intercom) {
  Vue.use(require('vue-intercom'), {
    appId: env.VUE_APP_INTERCOM,
  });
}

declare module 'vue/types/vue' {
  interface Vue {
    $intercom: any,
  }
}
/**
 * Sentry, error tracking
 */
if (isProductionEnvironment) {
  Sentry.init({
    Vue,
    dsn: env.VUE_APP_BLOQIFY_SENTRY,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        // tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
      }),
    ],
    environment: env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID,
    tracesSampleRate: 1.0,
  });
}

/**
 * We are exporting the collection promises from the bindings
 * so we can know when and where the data is still loading.
 * We also have to define the levels so Vue can attach observability when needed.
 */
export const collections: { [key: string]: Promise<any> | null } = {
  assets: null,
  admin: null,
  settings: null,
  idin: null,
  investor: null,
  idinIssuers: null,
  bankAccountChanges: null,
  pay: null,
  investments: null,
};

/**
 * Initialize Vue after Firebase is initialized. We use
 * Vuexfire to bind Firestore collections/documents.
 *
 * @see https://github.com/posva/vuexfire/tree/firestore
 */
export default (
  authInstance: firebase.auth.Auth,
  firestoreInstance: firebase.firestore.Firestore,
): Vue => {
  // Assigning collections
  collections.assets = store.dispatch('bindRef', {
    name: 'assets',
    ref: firestoreInstance.collection('assets')
      .where('deleted', '==', false)
      .orderBy('createdDateTime', 'desc'),
  });
  collections.admin = store.dispatch('bindRef', {
    name: 'admin',
    ref: firestoreInstance.collection('settings')
      .doc('admin'),
  });
  collections.settings = store.dispatch('bindRef', {
    name: 'settings',
    ref: firestoreInstance.collection('settings')
      .doc('generic'),
  });
  collections.identificationSettings = store.dispatch('bindRef', {
    name: 'identificationSettings',
    ref: firestoreInstance.collection('settings')
      .doc('identification'),
  });

  let app: Vue;
  let previousUser: firebase.User | null;

  authInstance.onAuthStateChanged(async (user): Promise<void> => {
    if (!app) {
      app = new Vue({
        el: '#app',
        i18n,
        router,
        store,
        render: (h): VNode => h(App),
      });
    }

    // Check if the user has any custom role
    const userHasRole = async (tempUser: firebase.User): Promise<boolean> => {
      const tokenResult = await tempUser.getIdTokenResult();
      return roles.some((role): boolean => tokenResult.claims[role]);
    };

    // Bind only if email is verified and user doesn't have any role
    if (user && user.emailVerified && !(await userHasRole(user))) {
      const { uid } = user;
      const investorRef = firestoreInstance.collection('investors').doc(uid);

      store.dispatch('logInInit', user);
      // Assigning collections
      collections.investor = store.dispatch(
        'bindRef',
        {
          name: 'user',
          ref: firestoreInstance.collection('investors').doc(uid),
        },
      );

      collections.idin = store.dispatch(
        'bindRef',
        {
          name: 'idin',
          ref: firestoreInstance.collection('idin').doc(uid),
        },
      );
      collections.idinIssuers = store.dispatch(
        'bindRef',
        {
          name: 'idinIssuers',
          ref: firestoreInstance.collection('idinIssuers').doc('issuers'),
        },
      );
      store.dispatch(
        'bindRef',
        {
          name: 'identificationRequest',
          ref: firestoreInstance.collection('identificationRequests').doc(uid),
        },
      );
      collections.bankAccountChanges = store.dispatch('bindRef', {
        name: 'bankAccountChanges',
        ref: firestoreInstance.collection('bankAccountChanges')
          .where('investor', '==', investorRef)
          .orderBy('createdDateTime', 'desc'),
      });

      collections.dataChangeRequests = store.dispatch('bindRef', {
        name: 'dataChangeRequests',
        ref: firestoreInstance.collection('dataChangeRequests')
          .where('investor', '==', investorRef),
      });

      collections.investments = store.dispatch('bindRef', {
        name: 'investments',
        ref: firestoreInstance.collection('investments')
          .where('investor', '==', investorRef)
          .orderBy('createdDateTime', 'desc'),
      });

      collections.payments = store.dispatch('bindRef', {
        name: 'payments',
        ref: firestoreInstance.collectionGroup('payments')
          .where('investor', '==', investorRef)
          .where('deleted', '==', false)
          .orderBy('updatedDateTime', 'desc'),
      });

      collections.valuations = store.dispatch('bindRef', {
        name: 'valuations',
        ref: firestoreInstance.collectionGroup('valuations')
          .where('deleted', '==', false)
          .orderBy('date', 'desc'),
      });

      // We cannot show investments that are from assets which have been removed or unpublished
      // 'in' queries have a limit of 10, so commenting this until it's fixed
      // https://firebase.googleblog.com/2019/11/cloud-firestore-now-supports-in-queries.html
      // try {
      //   const assets = await collections.assets as Asset[];
      //   collections.investments = store.dispatch('bindRef', {
      //     name: 'investments',
      //     ref: firestoreInstance.collection('investments')
      //       .where('userId', '==', uid)
      //       .where('assetId', 'in', assets.map((a: Asset): string => a.id))
      //       .orderBy('createdDateTime', 'desc'),
      //   });
      // } catch (error) {
      //   console.error(error);
      // }

      if (app.$intercom) {
        // Sending some information to the intercom admins.
        app.$intercom.update({
          email: user.email,
          user_id: uid,
        });
      }
    }

    if (previousUser && !user) {
      store.dispatch('logOutInit', user);
    }

    previousUser = user;
  });

  // @ts-ignore
  return app;
};
