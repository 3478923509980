/* eslint-disable object-shorthand */
import to from 'await-to-js';
import pull from 'lodash.pull';
import Vue from 'vue';
import { ActionContext, ActionTree } from 'vuex';
import { firestoreAction } from 'vuexfire';
import { capitalize } from '@/filters/string';
import { auth, db, firebase, functions, storage } from '@/firebase';
import { i18n } from '@/i18n';
import router from '@/router';
import { QuestionnaireAnswer, QuestionnaireAnswers } from '@/store/models/questionnaire';
import { DataChangeRequest, Investor, KYCMethods, roles } from '@/store/models/user';
// in order to make the this context available in certain actions
import * as MUTATIONS from './constants';
import { State } from './models';
import { AssetReservation } from './models/asset';
import { BusinessIdentification, PrivateIdentification } from './models/identificationRequest';

class Reference {
  // eslint-disable-next-line no-restricted-globals
  name: string = '';
  ref: any;
}

interface UnbindReference {
  name: string;
  reset?: boolean | Function;
}

export const activeBindings: string[] = [];

const getExtension = (type: string): string => type.substring(type.lastIndexOf('/') + 1, type.length);

export type SendQuestionnaireParam = { questionnaireAnswers: QuestionnaireAnswers, userId: string };

export default <ActionTree<State, State>>{
  // Creates data binding and stores key as reference in activeBindings
  bindRef: firestoreAction(
    ({ bindFirestoreRef }, { name, ref }: Reference): Promise<any> => {
      if (!activeBindings.includes(name)) {
        activeBindings.push(name);
      }

      return bindFirestoreRef(name, ref);
    },
  ),

  // Removes data binding and removes key from activeBindings
  unbindRef: firestoreAction(({ unbindFirestoreRef }, { name, reset }: UnbindReference): void => {
    if (reset === undefined) {
      unbindFirestoreRef(name);
    } else {
      // @ts-ignore (official types bugged)
      unbindFirestoreRef(name, reset);
    }
    pull(activeBindings, name);
  }),

  // Clears all bindings based on keys in activeBindings
  clearRefs: firestoreAction(({ unbindFirestoreRef }, exceptions: string[]): void => {
    const keys: string[] = activeBindings.filter((key: string): boolean => !exceptions.includes(key));

    keys.forEach((key: string): void => {
      // @ts-ignore (official types bugged)
      unbindFirestoreRef(key, true);
      pull(activeBindings, key);
    });
  }),

  logInInit({ commit }: ActionContext<State, State>, user: any): void {
    commit(MUTATIONS.LOGIN_SUCCESS, user);
  },

  logOutInit({ commit, dispatch }: ActionContext<State, State>): void {
    commit(MUTATIONS.LOGOUT_SUCCESS);

    const exceptions: string[] = ['assets', 'operations', 'downloads', 'settings', 'identificationSettings'];

    dispatch('clearRefs', exceptions);
    commit(MUTATIONS.RESET_STATE, exceptions);
  },

  async logIn(
    { commit, dispatch }: ActionContext<State, State>,
    { email, password }: any,
  ): Promise<void> {
    commit(MUTATIONS.LOGIN_PROCESSING);

    const [loginError, loginSuccess] = await to<firebase.auth.UserCredential, firebase.auth.Error>(
      auth.signInWithEmailAndPassword(email, password),
    );

    if (loginError) {
      commit(MUTATIONS.LOGIN_ERROR, loginError);
      return;
    }

    const user = loginSuccess!.user!;
    const userHasRole = async (): Promise<boolean> => {
      const tokenResult = await user.getIdTokenResult();
      return roles.some((role): boolean => tokenResult.claims[role]);
    };

    // Logout if email is not verified or user has a role
    if (!user.emailVerified || await userHasRole()) {
      await dispatch('logOut');
      commit(MUTATIONS.LOGIN_WARNING, loginSuccess);
    }

    if (!loginError) {
      try {
        // @ts-ignore
        this._vm.$gtm.trackEvent({
          event: 'login',
          email,
        });
      } catch (e) { /* Silent error */ }
    }
  },

  async logOut(
    { commit }: ActionContext<State, State>,
    { redirect }: { redirect?: string, idle?: boolean } = {},
  ): Promise<void> {
    commit(MUTATIONS.LOGOUT_PROCESSING);

    const [logOutError] = await to(auth.signOut());
    if (logOutError) {
      commit(MUTATIONS.LOGOUT_ERROR, logOutError);

      return;
    }

    commit(MUTATIONS.IDLE_LOGOUT);

    if (redirect) {
      // Silenting error at navigation abortion
      router.replace(redirect).catch((): void => {
      });
    }
  },

  async signUp(
    { commit, dispatch }: ActionContext<State, State>,
    { email, password }: { email: string, password: string },
  ): Promise<void> {
    return commit(MUTATIONS.SIGNUP_ERROR, Error('SignUp feature currently disabled'));

    // Create the user form the clientside
    const [signUpError] = await to<firebase.auth.UserCredential, firebase.auth.Error>(
      auth.createUserWithEmailAndPassword(email, password),
    );
    if (signUpError) {
      return commit(MUTATIONS.SIGNUP_ERROR, signUpError);
    }

    // Require login after registering
    dispatch('logOut');

    try {
      // @ts-ignore
      this._vm.$gtm.trackEvent({
        event: 'signup_started',
        email,
      });
    } catch (e) { /* Silent error */ }

    // Send verification e-mail via Cloud Function
    const [sendVerifyEmailError, sendVerifyEmailSuccess] = await to(
      dispatch('sendEmailVerification', { email }),
    );

    if (sendVerifyEmailError) {
      return commit(MUTATIONS.SIGNUP_ERROR, Error('There was a problem sending the verification email, please try again or contact support.'));
    }

    return commit(MUTATIONS.SIGNUP_SUCCESS);
  },

  async sendResetPassword({ commit }: ActionContext<State, State>, { email }: { email: string }): Promise<void> {
    commit(MUTATIONS.RESET_PASSWORD_PROCESSING);

    const [sendResetEmailError, sendResetEmailSuccess] = await to(
      functions.httpsCallable('sendUserActionEmail')({ action: 'password-reset', email, lang: i18n.locale }),
    );

    if (sendResetEmailError) {
      commit(MUTATIONS.RESET_PASSWORD_ERROR, sendResetEmailError);
    } else {
      commit(MUTATIONS.RESET_PASSWORD_SUCCESS, sendResetEmailSuccess);
    }
  },

  async contactSupportByMail(
    { commit, state, getters }: ActionContext<State, State>,
    data: any,
  ): Promise<void> {
    commit(MUTATIONS.CONTACT_SUPPORT_PROCESSING);

    const [sendEmailError, sendEmailSuccess] = await to(
      functions.httpsCallable('contactSupportByMail')(data),
    );

    if (sendEmailError) {
      commit(MUTATIONS.CONTACT_SUPPORT_ERROR, sendEmailError);
    } else {
      commit(MUTATIONS.CONTACT_SUPPORT_SUCCESS, sendEmailSuccess);
    }

    const eventData: { [key: string]: any } = {
      event: 'contact_form',
      email: state.user?.email,
    };

    if (getters.isInvestor()) {
      const investor = state.user as Investor;
      if (investor.kycMethod !== KYCMethods.Idin) {
        eventData.phone = investor.telephone;
        eventData.first_name = investor.name;
      }
      eventData.surname = investor.surname;
    }

    try {
      // @ts-ignore
      this._vm.$gtm.trackEvent(eventData);
    } catch (e) { /* Silent error */ }
  },

  async reAuthenticate(
    { commit }: ActionContext<State, State>,
    { password }: { password: string },
  ): Promise<[firebase.auth.Error | null, firebase.auth.UserCredential | undefined]> {
    const user = auth.currentUser as firebase.User;
    const currentCredentials = firebase.auth.EmailAuthProvider.credential(user.email || '', password);

    // Before we continue, we have to (and want to) re-authenticate
    // @see https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
    return to<firebase.auth.UserCredential, firebase.auth.Error>(
      user.reauthenticateWithCredential(currentCredentials),
    );
  },

  async changePassword(
    { commit, dispatch }: ActionContext<State, State>,
    { password, oldPassword }: { password: string, oldPassword: string },
  ): Promise<void> {
    commit(MUTATIONS.CHANGE_PASSWORD_PROCESSING);

    // Before we continue, we have to (and want to) re-authenticate
    // @see https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
    const [reAuthError] = await dispatch('reAuthenticate', { password: oldPassword });
    if (reAuthError) {
      return commit(MUTATIONS.CHANGE_PASSWORD_ERROR, reAuthError.code !== 'auth/wrong-password'
        ? reAuthError.message
        : capitalize(i18n.t('common.incorrectPassword') as string));
    }

    // Make sure we are re-authenticated before we reach this code
    // @see https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
    const [changePasswordError] = await to<void, firebase.auth.Error>(
      (auth.currentUser as firebase.User).updatePassword(password),
    );
    if (changePasswordError) {
      return commit(MUTATIONS.CHANGE_PASSWORD_ERROR, changePasswordError.message);
    }
    commit(MUTATIONS.CHANGE_PASSWORD_SUCCESS);

    // Require login after registering
    return dispatch('logOut', { redirect: '/login' });
  },

  async changeNameSurname(
    { commit, state }: ActionContext<State, State>,
    { name, surname }: { name: string, surname: string },
  ): Promise<void> {
    commit(MUTATIONS.CHANGE_NAME_SURNAME_PROCESSING);

    if (state.user) {
      const [updateNameError] = await to(
        db.collection('investors').doc(state.user.id).update({ name, surname, updatedDateTime: firebase.firestore.Timestamp.now() }),
      );
      if (updateNameError) {
        return commit(MUTATIONS.CHANGE_NAME_SURNAME_ERROR, updateNameError.message);
      }

      return commit(MUTATIONS.CHANGE_NAME_SURNAME_SUCCESS);
    }

    return commit(MUTATIONS.CHANGE_NAME_SURNAME_ERROR, 'Error with user credentials.');
  },

  async changeEmail(
    { commit, state, dispatch }: ActionContext<State, State>,
    { email, password }: { email: string, password: string },
  ): Promise<void> {
    commit(MUTATIONS.CHANGE_EMAIL_PROCESSING);

    const { user } = state;
    if (user && auth.currentUser) {
      if (!password) {
        return commit(MUTATIONS.CHANGE_EMAIL_ERROR, 'Please, fill in current password.');
      }

      // Before we continue, we have to (and want to) re-authenticate
      // @see https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
      const [reAuthError] = await dispatch('reAuthenticate', { password });

      if (reAuthError) {
        return commit(MUTATIONS.CHANGE_EMAIL_ERROR, reAuthError.message);
      }

      const [transactionError, transactionSuccess] = await to(
        db.runTransaction(async (transaction): Promise<void> => {
          const investorRef = db.collection('investors').doc(user.id);

          const [readBInvestorError, readInvestor] = await to(transaction.get(investorRef));
          if (readBInvestorError) {
            throw Error('There was an error reading the investor.');
          }
          if (readInvestor && !readInvestor.exists) {
            throw Error('Investor not found.');
          }

          transaction.update(investorRef, { newPendingEmail: email });

          // Update email in auth
          const [updateAuthEmailError] = await to(
            auth.currentUser!.verifyBeforeUpdateEmail(email),
          );

          if (updateAuthEmailError) {
            throw updateAuthEmailError;
          }
        }),
      );

      if (transactionError) {
        return commit(MUTATIONS.CHANGE_EMAIL_ERROR, 'Error at changing email.');
      }

      // Require login after registering
      return commit(MUTATIONS.CHANGE_EMAIL_SUCCESS);
    }

    return commit(MUTATIONS.CHANGE_EMAIL_ERROR, 'Error with user credentials.');
  },

  async sendEmailVerification({ commit }: ActionContext<State, State>, { email }: { email: string }): Promise<void> {
    commit(MUTATIONS.SEND_EMAIL_VERIFICATION_PROCESSING);

    // Use the Cloud Function to send the customised verification email
    const [sendVerifyEmailError, sendVerifyEmailSuccess] = await to(
      functions.httpsCallable('sendUserActionEmail')({ action: 'verify', email, lang: i18n.locale }),
    );

    if (sendVerifyEmailError) {
      commit(MUTATIONS.SEND_EMAIL_VERIFICATION_ERROR, sendVerifyEmailError);
    } else {
      commit(MUTATIONS.SEND_EMAIL_VERIFICATION_SUCCESS, sendVerifyEmailSuccess);
    }
  },

  async sendQuestionnaire(
    { commit, state }: ActionContext<State, State>,
    data: SendQuestionnaireParam,
  ): Promise<void> {
    commit(MUTATIONS.SEND_QUESTIONNAIRE_ANSWERS_PROCESSING);
    const storageUploads: firebase.storage.UploadTask[] = []; // collect the upload tasks in this array

    // todo don't upload files if already exists
    // store data if needed and transform to link
    const answersToStore: QuestionnaireAnswer[] = data.questionnaireAnswers.answers.map((answer, index): QuestionnaireAnswer => {
      if (answer.type === 'file') {
        const file = answer.answer as unknown as File;
        const fileName = `investors/${data.userId}/questionnaire${index}.${file.name}`;
        storageUploads.push(storage.ref().child(fileName).put(file));

        return {
          answer: fileName,
          type: 'file',
        };
      }
      return answer;
    });

    const [uploadAnswersError] = await to(Promise.all(storageUploads)); // upload files
    if (uploadAnswersError) {
      commit(MUTATIONS.SEND_QUESTIONNAIRE_ANSWERS_ERROR, uploadAnswersError.message);
      throw Error(uploadAnswersError.message);
    }

    const objToStore: QuestionnaireAnswers = {
      answers: answersToStore,
      createdDateTime: data.questionnaireAnswers.createdDateTime,
      questions: data.questionnaireAnswers.questions,
    };
    const [dbError] = await to( // write answers to firestore
      db
        .collection('investors')
        .doc(data.userId)
        .update({
          questionnaire: objToStore,
        }),
    );
    if (dbError) {
      commit(MUTATIONS.SEND_QUESTIONNAIRE_ANSWERS_ERROR, dbError.message);
      throw Error(dbError.message);
    }
    commit(MUTATIONS.SEND_QUESTIONNAIRE_ANSWERS_SUCCESS);
  },

  async sendPrivateIdentification(
    { commit, state }: ActionContext<State, State>,
    data: PrivateIdentification,
  ): Promise<void> {
    commit(MUTATIONS.SEND_PRIVATE_IDENTIFICATION_PROCESSING);

    const { passport } = data;

    // @ts-ignore
    const fileName = `investors/${state.user!.id}/passport.${getExtension(passport.type)}`;
    const storageRef = storage.ref().child(fileName);

    // @ts-ignore
    const [uploadError] = await to(storageRef.put(passport));

    if (uploadError) {
      return commit(MUTATIONS.SEND_PRIVATE_IDENTIFICATION_ERROR, uploadError.message);
    }

    const [saveToDbError] = await to(
      functions.httpsCallable('createIdentificationRequest')({
        ...data,
        passport: fileName,
      }),
    );

    if (saveToDbError) {
      return commit(MUTATIONS.SEND_PRIVATE_IDENTIFICATION_ERROR, saveToDbError.message);
    }

    try {
      // @ts-ignore
      this._vm.$gtm.trackEvent({
        event: 'verification_started',
        type: 'PrivateIdentification',
        email: state.user!.email,
        first_name: data.name,
        last_name: data.surname,
        phone: data.telephone,
        birthdate: data.dateOfBirth,
        adress: data.streetAddress,
        housenumber: data.houseNumber,
        postcode: data.postalCode,
        country: data.country,
      });
    } catch (e) { /* Silent error */ }

    return commit(MUTATIONS.SEND_PRIVATE_IDENTIFICATION_SUCCESS);
  },

  async sendBusinessIdentification(
    { commit, state }: ActionContext<State, State>,
    data: BusinessIdentification,
  ): Promise<void> {
    commit(MUTATIONS.SEND_BUSINESS_IDENTIFICATION_PROCESSING);

    const user = auth.currentUser as firebase.User;

    const { passport } = data;
    const { kvkImage } = data;
    const filePaths = {};
    const storageRef = storage.ref();

    const fileHandler = async (file: File, path: 'passport' | 'kvkImage'): Promise<void> => {
      if (file) {
        const filePath = `investors/${state.user!.id}/${path}.${getExtension(file.type)}`;
        const fileRef = storageRef.child(filePath);
        filePaths[path] = filePath;
        await fileRef.put(file);
      }
    };
    try {
      if (passport) {
        fileHandler(passport as unknown as File, 'passport');
      }
      if (kvkImage) {
        fileHandler(kvkImage as unknown as File, 'kvkImage');
      }
    } catch (uploadError) {
      return commit(MUTATIONS.SEND_PRIVATE_IDENTIFICATION_ERROR, (uploadError as Error).message);
    }

    const [saveToDbError] = await to(
      functions.httpsCallable('createIdentificationRequest')({
        ...data,
        ...filePaths,
      }),
    );

    if (saveToDbError) {
      return commit(MUTATIONS.SEND_BUSINESS_IDENTIFICATION_ERROR, saveToDbError.message);
    }

    try {
      // @ts-ignore
      this._vm.$gtm.trackEvent({
        event: 'verification_started',
        type: 'BusinessIdentification',
        email: user.email,
        first_name: data.name,
        last_name: data.surname,
        phone: data.telephone,
        birthdate: data.dateOfBirth,
        adress: data.streetAddress,
        housenumber: data.houseNumber,
        postcode: data.postalCode,
        country: data.country,
        companyId: data.companyId,
        kvk: data.kvk,
      });
    } catch (e) { /* Silent error */ }

    return commit(MUTATIONS.SEND_BUSINESS_IDENTIFICATION_SUCCESS);
  },

  async changeBankAccount(
    { commit }: ActionContext<State, State>,
    bankAccount: string,
  ): Promise<void> {
    commit(MUTATIONS.CHANGE_BANK_ACCOUNT_EMAIL_PROCESSING);

    // ToDo: check if this is necessary.
    await Vue.nextTick();

    const [changeBankAccountEmailError] = await to(
      functions.httpsCallable('bankAccountChange')({ bankAccount }),
    );

    if (changeBankAccountEmailError) {
      return commit(MUTATIONS.CHANGE_BANK_ACCOUNT_EMAIL_ERROR, changeBankAccountEmailError.message);
    }

    return commit(MUTATIONS.CHANGE_BANK_ACCOUNT_EMAIL_SUCCESS);
  },

  async confirmBankAccountChange(
    { commit }: ActionContext<State, State>,
    id: string,
  ): Promise<void> {
    commit(MUTATIONS.CHANGE_BANK_ACCOUNT_PROCESSING);

    const [updateDataError] = await to(
      functions.httpsCallable('bankAccountUpdate')({ id }),
    );

    if (updateDataError) {
      return commit(MUTATIONS.CHANGE_BANK_ACCOUNT_ERROR, 'There was a problem updating the bank account number.');
    }

    return commit(MUTATIONS.CHANGE_BANK_ACCOUNT_SUCCESS);
  },

  async emailVerification(
    { commit, state }: ActionContext<State, State>,
    { actionCode, mode, email }: { actionCode: string, mode: string, email: string },
  ): Promise<void> {
    commit(MUTATIONS.EMAIL_VERIFICATION_PROCESSING);

    if (mode === 'verifyAndChangeEmail') {
      const [transactionError] = await to(
        db.runTransaction(async (transaction): Promise<void> => {
          const [_, getInvestorQuerySuccess] = await to(db.collection('investors').where('newPendingEmail', '==', email).limit(1).get());

          if (getInvestorQuerySuccess && getInvestorQuerySuccess.docs && getInvestorQuerySuccess.docs.length > 0) {
            const investorRef = getInvestorQuerySuccess.docs[0].ref;

            transaction.update(investorRef, { email, newPendingEmail: firebase.firestore.FieldValue.delete() });

            const [verifyError] = await to(auth.applyActionCode(actionCode));
            if (verifyError) {
              throw verifyError;
            }
          } else {
            throw Error('Error verifying the email.');
          }
        }),
      );

      if (transactionError) {
        commit(MUTATIONS.EMAIL_VERIFICATION_ERROR, transactionError.message);
        return;
      }

      commit(MUTATIONS.EMAIL_VERIFICATION_SUCCESS);

      try {
        // @ts-ignore
        this._vm.$gtm.trackEvent({
          event: 'email_verification_completed',
          email: state.user?.email,
        });
      } catch (e) { /* Silent error */ }

      return;
    }

    const [verifyError, verifySuccess] = await to(auth.applyActionCode(actionCode));

    if (verifyError) {
      commit(MUTATIONS.EMAIL_VERIFICATION_ERROR, verifyError.message);
      return;
    }

    commit(MUTATIONS.EMAIL_VERIFICATION_SUCCESS, verifySuccess);

    try {
      // @ts-ignore
      this._vm.$gtm.trackEvent({
        event: 'signup_completed',
        email: state.user?.email,
      });
    } catch (e) { /* Silent error */ }

    return;
  },

  async resetPassword(
    { commit }: ActionContext<State, State>,
    { actionCode, newPassword }: { actionCode: string, newPassword: string },
  ): Promise<void> {
    commit(MUTATIONS.CONFIRM_PASSWORD_RESET_PROCESSING);

    const [confirmError, confirmSuccess] = await to(auth.confirmPasswordReset(actionCode, newPassword));
    if (confirmError) {
      return commit(MUTATIONS.CONFIRM_PASSWORD_RESET_ERROR, confirmError.message);
    }

    return commit(MUTATIONS.CONFIRM_PASSWORD_RESET_SUCCESS, confirmSuccess);
  },

  async recoverEmail(
    { commit }: ActionContext<State, State>,
    { actionCode }: { actionCode: string },
  ): Promise<void> {
    commit(MUTATIONS.RECOVER_EMAIL_PROCESSING);

    const [confirmError, confirmSuccess] = await to(auth.applyActionCode(actionCode));
    if (confirmError) {
      commit(MUTATIONS.RECOVER_EMAIL_ERROR, confirmError.message);
    }

    return commit(MUTATIONS.RECOVER_EMAIL_SUCCESS, confirmSuccess);
  },

  async revertSecondFactor(
    { commit }: ActionContext<State, State>,
    { actionCode }: { actionCode: string },
  ): Promise<void> {
    commit(MUTATIONS.REVERT_SECOND_FACTOR_PROCESSING);

    const [confirmError, confirmSuccess] = await to(auth.applyActionCode(actionCode));

    if (confirmError) {
      return commit(MUTATIONS.REVERT_SECOND_FACTOR_ERROR, confirmError.message);
    }

    return commit(MUTATIONS.REVERT_SECOND_FACTOR_SUCCESS, confirmSuccess);
  },

  async checkActionCode(
    { commit }: ActionContext<State, State>,
    { actionCode }: { actionCode: string },
  ): Promise<void> {
    commit(MUTATIONS.CHECK_ACTION_CODE_PROCESSING);

    const [confirmError, confirmSuccess] = await to(auth.checkActionCode(actionCode));
    if (confirmError) {
      commit(MUTATIONS.CHECK_ACTION_CODE_ERROR, confirmError.message);
    }

    return commit(MUTATIONS.CHECK_ACTION_CODE_SUCCESS, confirmSuccess);
  },

  activateInitialTooltip({ commit }: ActionContext<State, State>): void {
    commit(MUTATIONS.SHOW_INITIAL_TOOLTIP);

    setTimeout((): void => {
      commit(MUTATIONS.HIDE_INITIAL_TOOLTIP);
    }, 6000);
  },

  async createDataChangeRequest(
    { commit, state }: ActionContext<State, State>,
    { type, newData, previousData }: DataChangeRequest,
  ): Promise<void> {
    commit(MUTATIONS.CREATE_DATA_CHANGE_REQUEST_PROCESSING);

    const investor = db.collection('investors').doc(state.user?.id);

    const [getBankAccountDataChangeError, getDataChangeRequestSuccess] = await to(
      db
        .collection('dataChangeRequests')
        .where('investor', '==', investor)
        .where('type', '==', type)
        .where('status', '==', 'pending')
        .get(),
    );

    if (getBankAccountDataChangeError || getDataChangeRequestSuccess?.docs.length) {
      commit(MUTATIONS.CREATE_DATA_CHANGE_REQUEST_ERROR, getBankAccountDataChangeError?.message || 'Pending data change request for this type already exists.');
    } else {
      const previousDataIsValid = Object.values(previousData).every((val): boolean => !!val);

      const [createDataChangeError] = await to(
        db
          .collection('dataChangeRequests')
          .add({
            investor,
            type,
            newData,
            ...previousDataIsValid && {
              previousData,
            },
            status: 'pending',
          }),
      );

      if (createDataChangeError) {
        commit(MUTATIONS.CREATE_DATA_CHANGE_REQUEST_ERROR, createDataChangeError.message);
      }

      commit(MUTATIONS.CREATE_DATA_CHANGE_REQUEST_SUCCESS);
    }
  },

  async assetReservation(
    { commit }: ActionContext<State, State>,
    { name, email, asset, telephone, amount }: AssetReservation,
  ): Promise<void> {
    commit(MUTATIONS.ASSET_RESERVATION_PROCESSING);

    const [assetReservationError] = await to(
      functions.httpsCallable('assetReservation')({ name, email, asset, telephone, amount }),
    );

    if (assetReservationError) {
      return commit(MUTATIONS.ASSET_RESERVATION_ERROR, 'There was a problem submitting reservation to the asset.');
    }

    return commit(MUTATIONS.ASSET_RESERVATION_SUCCESS);
  },
};
