import { Module } from 'vuex';
import { firebase } from '@/firebase';
import { State } from '@/store/models';
import { Valuation } from '@/store/models/valuation';

export default <Module<Valuation[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getActiveValuationByAsset: (state): Function =>
      (assetId: string): Valuation | undefined => {
        const dateNow = new Date();

        return state.find((valuation): boolean => valuation.asset.id === assetId &&
          valuation.date <= firebase.firestore.Timestamp.fromMillis(Date.UTC(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), dateNow.getHours())));
    },

    getValuationsByAsset: (state): Function =>
      (assetId: string): Valuation[] | [] => {
        const valuations = state.filter((valuation): boolean => valuation.asset.id === assetId);
        if (valuations) return valuations.sort((a, b): number => b.date.toMillis() - a.date.toMillis());
        return [];
    },
  },
};
